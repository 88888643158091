import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { useContext, useRef } from 'react';
import LanguageContext from '../../../context/LanguageContext/LanguageContext';
import DataContext from '../../../context/DataContext';
import useQuery from '../../../hooks/useQuery';
import { ReplaceText } from '../ReplaceText';
import BiteIcon from '../../Icons/Types/Bite';

const ContentCard4 = ({content}) => {

    const {langContent} = useContext(LanguageContext);
    const {showLinkCopied} = useContext(DataContext);
    const searchInput = useQuery().get('q');
    const contentcarddropdownRef = useRef(null);

    const toggleSearchOptions = (className) => {
        document.querySelector(`.select_options_${className}`).classList.toggle('hidden');
    }
    const hideSearchOptions = (ref) => {
        ref.current.children[1].classList.add('hidden');
    }
    useOutsideAlerter(contentcarddropdownRef, hideSearchOptions);

    const copyLinkToClipboard = () => {
        navigator.clipboard
        .writeText(content.link)
        .then(() => {
            showLinkCopied();
        })
        .catch(() => {
            console.log('Link not copied.');
        });
        hideSearchOptions(contentcarddropdownRef);
    }

    const addListScrollReminder = () => {
        window.history.pushState(null,null,'#list-'+content.id);
    }
    
    return (
        <div id = {`list-${content.id}`} className='listCard card-type-4 relative shadow-shadowCard'>

            <a onClick = {addListScrollReminder} href = {content.link} className={`flex md:h-[250px] content-card cc4 bg-white w-full mb-4 rounded-[8px] cursor-pointer md:border-l-4 border-white ${content.has_access > 1 && 'border-[#28BF73] border-t-4 '}`}>
                <div className='flex justify-between w-full flex-col-reverse md:flex-row'>
                    <div className="flex flex-col p-4 md:p-6 relative">

                        <div className="title-part">
                            <Link to="/search?type=bite" className="h-[32px] rounded-[64px] bg-[#E9FBF2] inline-flex justify-center text-sm text-[#187747] font-[500] items-center shrink-0 px-2 mb-1">
                                <BiteIcon className="text-[#187747] w-4 h-4" />
                                <span className="ml-1.5">ÕPIAMPSUD</span>
                            </Link>
                            <div className="flex flex-col">
                                <h2 className="text-[18px] sm:text-[24px] font-[500] text-[#0C1135] leading-[140%]">{Parser(ReplaceText(content.title,searchInput))}</h2>
                            </div>
                        </div>
                        {content.text && <div className="text-[14px] sm:text-[16px] mt-1 text-[#414668] leading-[150%] overflow-hidden md:max-h-[100px]">{content.text !== undefined && Parser(ReplaceText(content.text, searchInput))}</div>}
                        <div className='flex overflow-visible flex-wrap mt-4 leading-4 tracking-[0.05em] font-[500] text-[14px] text-[#414668] mt-auto pt-2 items-center'>
                            <p className={`shrink-0 uppercase text-[14px] sm:text-[14px] font-[500] rounded-[4px] leading-3.5 tracking-0 py-1 px-1.5 mr-4 mb-1 2xl:mb-0 bg-[#EBEEFE] ${content.price_color === '28BF73' ? 'text-[#323449] ' : 'text-primary'}`}>
                                {content.price}
                            </p>
                            <div className="flex justify-start items-center">
                            {/* parent */}
                            {content.parent && 
                                <a href={content.parent_link} className = 'shrink-0 relative z-[1] mr-4 hover:underline'><p className=''>{content.parent}</p></a>
                            }
                            {/* subjects */}
                            {content.subjects && content.subjects.length && content.subjects.map((subject, index) => {
                                if(index < content.subjects.length - 1) {
                                    return(
                                        <Link to = {`/search?subject=${subject.slug}`} key = {index} className='shrink-0 relative z-[1] hover:underline'>
                                            <p className='whitespace-pre-wrap'>{subject.name}, </p>
                                        </Link>
                                    )
                                }
                                else{
                                    return(
                                        <Link to = {`/search?subject=${subject.slug}`} key = {index} className = 'shrink-0 mr-4 relative z-[1] hover:underline'>
                                            <p>{subject.name}</p>
                                        </Link>
                                    )
                                }
                            })}
                            
                        
                            {/* grades */}
                            {content.grades && content.grades.length && content.grades.map((grade, index) => {
                                if(index < content.grades.length - 1) {
                                return(
                                    <Link to = {`/search?grade=${grade.slug}`} key = {index} className='shrink-0 relative z-[1] hover:underline'>
                                        <p className='whitespace-pre-wrap'>{grade.name}, </p>
                                    </Link>
                                )
                                }else{
                                    return(
                                        <Link to = {`/search?grade=${grade.slug}`} key = {index} className='shrink-0 relative z-[1] mr-4 hover:underline'>
                                            <p className='whitespace-pre-wrap'>{grade.name}</p>
                                        </Link>
                                    )
                                }
                            })}
                            </div>
                                
                        </div>
                    </div>
                    
                    
                    <div className='md:w-[400px] shrink-0 block w-full aspect-video bg-cover bg-center rounded-t-lg md:rounded-tl-none md:rounded-r-lg md:h-[250px]' style= {{ backgroundImage: `url(${content.image})` }} >
                        {/* <img src = {content.image} alt = {content.title} className='w-full h-full object-cover rounded-b-lg' /> */}
                    </div>
                    
                </div>
            </a>
            {/* {content.options && 
                <div ref={contentcarddropdownRef} className="absolute z-[1] top-0 right-2">
                    <button className="flex px-4 py-6 items-center text-sm w-full font-[500] border-0 select_container cursor-pointer"
                        onClick={() => toggleSearchOptions(`contentcarddropdown${content.id}`)}>
                        <span className="teacher_menu p-2 -mt-2 -mr-2 hover:bg-[#0c1135] text-[#938FA8] hover:text-white rounded-[50%] bg-[#ece9fc]">

                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_708_935)">
                            <path d="M7.99992 4.33333C9.19654 4.33333 10.1666 3.36328 10.1666 2.16667C10.1666 0.97005 9.19654 0 7.99992 0C6.8033 0 5.83325 0.97005 5.83325 2.16667C5.83325 3.36328 6.8033 4.33333 7.99992 4.33333Z" fill="currentcolor"/>
                            <path d="M7.99992 10.1673C9.19654 10.1673 10.1666 9.19727 10.1666 8.00065C10.1666 6.80403 9.19654 5.83398 7.99992 5.83398C6.8033 5.83398 5.83325 6.80403 5.83325 8.00065C5.83325 9.19727 6.8033 10.1673 7.99992 10.1673Z" fill="currentcolor"/>
                            <path d="M7.99992 15.9993C9.19654 15.9993 10.1666 15.0293 10.1666 13.8327C10.1666 12.6361 9.19654 11.666 7.99992 11.666C6.8033 11.666 5.83325 12.6361 5.83325 13.8327C5.83325 15.0293 6.8033 15.9993 7.99992 15.9993Z" fill="currentcolor"/></g><defs><clipPath id="clip0_708_935"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>


                        </span>
                    </button>
                    <div className={`z-[2] shadow-shadow4 w-[180px] select_options_contentcarddropdown${content.id} select_options hidden rounded-[8px] shadow-shadow3 flex flex-col absolute right-0 top-[50px] sm:top-[52px] bg-white`}>
                
                        <button onClick = {copyLinkToClipboard} className = 'w-full flex items-center text-left px-4 py-3.5 text-[#57546d] hover:text-[#6d53e4] font-[500] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em]'>
                            <svg xmlns="http://www.w3.org/2000/svg" className='mr-2' width="16" height="16"><g fill="none" stroke="#0C1135" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path strokeWidth="1.000005" d="M13.5 5.5v-2a1 1 0 0 0-1-1H9.8M4.2 2.5H1.5a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4"/><path strokeWidth="1.000005" d="M9.6 3.2a.5.5 0 0 1-.5.3H5a.5.5 0 0 1-.5-.3l-.7-2a.5.5 0 0 1 .5-.7h5.6a.5.5 0 0 1 .5.7ZM7.5 7.5h8v8h-8ZM9.5 9.5h4M9.5 11.5h4M9.5 13.5H11"/></g></svg>
                            {langContent.resultItem.copylink ? langContent.resultItem.copylink : "Kopeeri link"}
                        </button>                               
                        
                        {content.options.map((option, index) => {
                            return(
                                <a href={option.link} key={index} className = 'w-full flex items-center text-left px-4 py-3.5 text-[#57546d] hover:text-[#6d53e4] font-[500] hover:bg-[#ebeefe] text-[14px] leading-[16px] tracking-[0.05em]'>                            
                                    <img src={option.icon} alt = {option.label} className = 'w-[16px] h-[16px] mr-2' />
                                    {option.label}
                                </a> 
                            )
                        })}  
                    </div>
                </div>
            } */}
        </div>
    )
}

export default ContentCard4;